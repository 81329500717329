<template>
  <el-row>
    <el-form label-width="120px">
      <el-form-item label="周期模板">
        <el-radio-group v-model="dataInfo.subType" @change="refresh(true)">
          <el-radio-button label="A">模板一</el-radio-button>
          <el-radio-button label="B">模板二</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="触发器名称">
        <el-input :readonly="true" v-model="dataInfo.triggerName" class="readonly"></el-input>
      </el-form-item>
      <el-form-item label="任务生成说明">
        <el-input :readonly="true" v-model="dataInfo.taskCreatorDesc" class="readonly"></el-input>
      </el-form-item>
    </el-form>
    <div v-if="dataInfo.subType === 'A'">
      <el-form label-width="120px">
        <el-form-item label="每季度">
          <l-select v-model="dataInfo.monthA" :all="false" :data="$constant.BaseQuarter" @change="refresh()" style="width:100px"></l-select>
          <span class="padding-lr-mini"></span>
          <l-select v-model="dataInfo.dayA" :all="false" :data="$constant.BaseDays" @change="refresh()" style="width:80px"></l-select>
          <span class="padding-lr-xs">至</span>
          <l-select v-model="dataInfo.monthB" :all="false" :data="$constant.BaseQuarter" @change="refresh()" style="width:100px"></l-select>
          <span class="padding-lr-mini"></span>
          <l-select v-model="dataInfo.dayB" :all="false" :data="$constant.BaseDays" @change="refresh()" style="width:80px"></l-select>
          <span class="padding-lr-xs text-bold text-green">可执行</span>
        </el-form-item>
      </el-form>
    </div>
    <div v-else-if="dataInfo.subType === 'B'">
      <el-form label-width="120px">
        <el-form-item label="每季度">
          <l-select v-model="dataInfo.monthB" :all="false" :data="$constant.BaseQuarter" @change="refresh()" style="width:100px"></l-select>
          <span class="padding-lr-mini"></span>
          <l-select v-model="dataInfo.dayB" :all="false" :data="$constant.BaseDays" @change="refresh()" style="width:80px"></l-select>
          <span class="padding-lr-xs">当日</span>
          <span class="padding-lr-xs text-bold text-green">可执行</span>
        </el-form-item>
        <el-form-item label="提前">
          <el-input-number v-model="dataInfo.dayA" size="mini" :step="1" step-strictly @change="refresh()" style="width:100px"></el-input-number>
          <span class="padding-lr-xs">日</span>
          <span class="padding-lr-mini text-bold text-blue">生成</span>
          <span class="padding-lr-xs">可执行任务</span>
        </el-form-item>
      </el-form>
    </div>
  </el-row>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    refresh(reset) {
      this.$emit('refresh', {
        data: this.dataInfo,
        reset: !!reset
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
